<template>    
  <v-dialog max-width="1024px" v-model="dialog">
    <v-card style="height:calc(100vh - 200px);">              
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3">
          비상호출
          </v-toolbar-title>          
          <v-divider class="mx-4" inset vertical></v-divider>                              
        </v-toolbar>
        <v-divider></v-divider>      
      <v-data-table :items-per-page="-1" fixed-header         
        :headers="headers" 
        :items="Records" 
        item-key="cIdx"                   
        height="calc(100vh - 345px)"
        @dblclick:row="handleDoubleClick"
      >        
        <template v-slot:item.cSel="{ item }">                    
          <v-btn text @click="selectItem(item)"><v-icon>mdi-check-circle</v-icon></v-btn>          
        </template>
        <template v-slot:item.cMapFullNm="{ item }">
          <v-btn text @click="showPos(item)"><v-icon class="mr-1">mdi-map-marker</v-icon>{{item.cMapFullNm}}</v-btn>
        </template>
        <template v-slot:item.cNowMapNm="{ item }">
          <v-btn text @click="showNowPos(item)"><v-icon class="mr-1">mdi-map-marker</v-icon>{{item.cNowMapNm}}{{item.cIsNowAp=='Y'?'':' 근처'}}</v-btn>
        </template>
        <template v-slot:no-data>
          <p>No data available.</p>
        </template>
      </v-data-table>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer> 
        <v-btn color="grey darken-2" text @click="closePage()"
          ><v-icon class="mr-2">mdi-cancel</v-icon>취소</v-btn>
        <!-- <v-btn color="blue darken-1" text @click="SelectData()"
          ><v-icon class="mr-2">check_circle</v-icon>확인</v-btn> -->
        
        
      </v-card-actions>
    </v-card>    
    <MapViewPOS ref="MapViewPOSRef"/> 
  </v-dialog>  
</template>

<script>
import MapViewPOS from "@/components/MapViewPOS.vue";
import BasicInfo from "@/BasicInfo.js";
import EventBus from '@/eventBus.js';

export default {
  components: { 
    MapViewPOS,   
  },
  data() {
    return {
      dialog: false,
      sDate : "",      
      model : null,      
      selected: [],
      cNowSearchKeyWord : "",
      headers: [        
        // { class: "font-weight-bold subtitle-2",  text: "아이디", value: "cApId", width: 120 },
        { class: "font-weight-bold subtitle-2", align: "center", text: "조치", value: "cSel", width: 50, sortable: false },     
        { class: "font-weight-bold subtitle-2", align: "center", text: "일시", value: "cCreateDateTime", width: 150 },      
        { class: "font-weight-bold subtitle-2", align: "center", text: "이름", value: "cUserNm", width: 80 },      
        { class: "font-weight-bold subtitle-2", align: "center", text: "연락처", value: "cTel", width: 150 },        
        // { class: "font-weight-bold subtitle-2", align: "center", text: "MAC", value: "cMac", width: 150 },        
        { class: "font-weight-bold subtitle-2", align: "center", text: "발생위치", value: "cMapFullNm", width: 120 },
        { class: "font-weight-bold subtitle-2", align: "center", text: "현재위치", value: "cNowMapNm", width: 100 },
      ],
      Records: [],
    };
  },  
  created() {
    this.dialog = false;
  },
  watch: {
    dialog(val) {
      val || this.closePage();
    }
  },
  mounted () {    
  },
  methods: {    
    closePage: function() {                
      this.dialog = false;
    },

    showPos(item){
      if (BasicInfo.Is3D_MapMode) {
        EventBus.$emit("onMapPosDashboard", item.cAPId);
        this.dialog = false;
      } else {
        this.$refs.MapViewPOSRef.Show("발생위치", item, item.cPath, item.x, item.y, "비상호출", item.cMapFullNm, item.cUserNm);
      }
    },
    showNowPos(item){
      if (BasicInfo.Is3D_MapMode) {
        EventBus.$emit("onMapPosDashboard", item.cNowApId);
        this.dialog = false;
      } else {
        this.$refs.MapViewPOSRef.Show("현재위치", item, item.cNowPath, item.nowX, item.nowY, "비상호출", item.cNowMapNm, item.cUserNm);
      }
    },

    getColor(cData) {
      if (cData === "NONE") return "#70bbfe";
      else return "#707070";
    },    

    handleDoubleClick(value, items) { 
      console.log(value, items);
      this.selectItem(items.item);          
    },

    selectItem(item) {
      this.$emit("onSelect", item);
      this.dialog = false;  
    },

    Show(data) {             
      this.selected = [];
      this.Records = data;      
      this.cNowSearchKeyWord = "";
      this.dialog = true;            
    },    
  }
};
</script>

